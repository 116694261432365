import React from 'react'
import Farmer from '../img/farmer.png'
import Star from '../img/star1.png'
import Btc from '../img/btc_shop.png'

const Farming = () => {
  return (
    <>
         <section className='sec01_t text-center'>
            <div className='box d-inline'>
              <img className='Edit' src={Farmer} />
              <span className='rank wc'>Farm Grey Night Marble</span>
            </div>

            <div className='mt-5 text-center'>
                <div className='d-flex star_box'>
                  <img className='Edit' src={Star} />
                  <img className='Edit' src={Star} />
                  <img className='Edit' src={Star} />
                </div>
              <h4 className='gnm wc'>Claim Grey Night Marble and keep</h4>
              <p className='icon_box_text mb-0'>Farming while tapping!</p>
            </div>

            <div className='box farming_box'>
                <h4 className='gnm grc'>Current farming era</h4>
                <h4 className='gnm wc mt-3'>03:57:28</h4>
            </div>
            <div className='box farming_box'>
                <h4 className='gnm grc'>Farming era reward</h4>
                <h4 className='gnm wc mt-3'>12 Grey Night Marble</h4>
                <img className='btc_s' src={Btc} />
            </div>
            <button className='btn_p'>Claim</button>
         </section>

    </>
  )
}

export default Farming