import React from 'react'

import Man from '../img/man.png'
import Megaphone from '../img/megaphone.png'
import Edit from '../img/to-do-list.png'
import Twitter from '../img/twitter.png'
import Telegram from '../img/telegram.png'
import Web from '../img/web.png'
import User from '../img/user.jpg'
import Fbattery from '../img/full-battery.png'
import Light from '../img/lighting.png'
import Fire from '../img/fire.png'
import battery from '../img/battery.png'
import Privacy from '../img/privacy.png'
import Shuttle from '../img/shuttle1.png'


const NFTMarketplace = () => {
  return (
    <>
    <section className='sec01_t text-center'>
            <div className='box d-inline'>
              <img className='Edit' src={Edit} />
              <span className='rank wc'>NFT Marketplace</span>
            </div>

        <nav className='box tabs_nav'>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link tab_btn active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Shop</button>
                <button class="nav-link tab_btn" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Daily</button>
                <button class="nav-link tab_btn" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Boosters</button>
            </div>
        </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className='tab_box1'>
                        <div className='row'>
                            <div className='col-6 '>
                                <img className='man_icon' src={Man} />
                                <h5 className='man_title wc'>Gold Grey Night Marble 0.1 BNB</h5>
                                <p className='man_text wc'>+ 200  Grey Night / day Bonus farm + 20%</p>

                                <button className='buy'>Buy</button>
                            </div>
                            <div className='col-6'>
                                <img className='man_icon' src={Man} />
                                <h5 className='man_title wc'>Blue Grey Night Marble 0.2 BNB</h5>
                                <p className='man_text wc'>+ 400  Grey Night / day Bonus farm + 40%</p>

                                <button className='buy'>Buy</button>
                            </div>

                            <div className='col-6 mt-5'>
                                <img className='man_icon' src={Man} />
                                <h5 className='man_title wc'>Red Grey Night Marble 0.5 BNB</h5>
                                <p className='man_text wc'>+ 1000  Grey Night / day Bonus farm +100%</p>

                                <button className='buy'>Buy</button>
                            </div>

                            <div className='col-6 mt-5'>
                                <img className='man_icon' src={Man} />
                                <h5 className='man_title wc'>Violet Grey Night Marble 1.0 BNB</h5>
                                <p className='man_text wc'>+ 2000  Grey Night / day Bonus farm + 200%</p>

                                <button className='buy'>Buy</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div className='tab_box1'>
                        <div className='d-inline '>
                            <img className='Megaphone' src={Megaphone} />
                            <span className='rank wc'>Your daily booster</span>
                        </div>

                        <div className='box invite invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    
                                    <img className='icon_box_icon1' src={Fbattery} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Fill your energy to the max</h4>
                                    <p className='icon_box_text mb-0'> invite +1 friend Today</p>
                                </div>
                                <div className='align-c'>
                                    <h4 className='start'>Start</h4>
                                </div>
                            </div>
                            <button className='btn_p'>Please invite +1 Today!</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    
                                    <img className='icon_box_icon1' src={Fire} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Fill your energy to the max</h4>
                                    <p className='icon_box_text mb-0'> Finished Socials Tasks</p>
                                </div>
                                <div className='align-c'>
                                    <h4 className='start'>Start</h4>
                                </div>
                            </div>
                            <button className='btn_p'>Please finished Socials Tasks Today!</button>
                        </div>

                        <div className=' invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                            
                                    <img className='icon_box_icon1' src={Light} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Fill your energy to the max</h4>
                                    <p className='icon_box_text mb-0'> One Per day</p>
                                </div>
                                
                            </div>
                            <button className='btn_p'>Get it!</button>
                        </div>

                    
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <div className='tab_box1'>
                        <div className='d-inline '>
                            <img className='Megaphone' src={Megaphone} />
                            <span className='rank wc'>Upgrade Boosters</span>
                        </div>

                        <div className='box invite invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    
                                    <img className='icon_box_icon1' src={Privacy} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Multitap - 1 x Grey Night / Tap</h4>
                                    <p className='icon_box_text mb-0'> +1 per tap for each level</p>
                                </div>
                                
                            </div>
                            <button className='btn_p'>10000 x Grey Night | Get it!</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    
                                    <img className='icon_box_icon1' src={battery} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Energy Limit  - 100 x Energy / Day</h4>
                                    <p className='icon_box_text mb-0'> +50 energy limit for each level</p>
                                </div>
                                
                            </div>
                            <button className='btn_p'>15000 x Grey Night | Get it!</button>
                        </div>

                    </div>
                </div>
            </div>
    </section>
    </>
  )
}

export default NFTMarketplace