import React from 'react'
import Like from '../img/like.png'
import User from '../img/Avatar.png'
import Megaphone from '../img/megaphone.png'
import Edit from '../img/to-do-list.png'
import Twitter from '../img/twitter.png'
import Telegram from '../img/telegram.png'
import Web from '../img/web.png'

const Tasks = () => {
  return (
    <>
    <section className='sec01_t text-center'>
            <div className='box d-inline'>
              <img className='Edit' src={Edit} />
              <span className='rank wc'>Tasks</span>
            </div>

        <nav className='box tabs_nav'>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link tab_btn active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Ref</button>
                <button class="nav-link tab_btn" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Daily</button>
                <button class="nav-link tab_btn" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Socials</button>
            </div>
        </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className='tab_box1'>
                        <div className='d-inline '>
                            <img className='Megaphone' src={Megaphone} />
                            <span className='rank wc'>Invite your Friends</span>
                        </div>

                        <div className='box invite invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 1</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Invite 1 Friends</h4>
                                    <p className='icon_box_text mb-0'> 2,000 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 2</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Invite 5 Friends</h4>
                                    <p className='icon_box_text mb-0'> 2,000 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 3</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Invite 10 Friends</h4>
                                    <p className='icon_box_text mb-0'> 2,000 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 4</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Invite 20 Friends</h4>
                                    <p className='icon_box_text mb-0'> 2,000 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div className='tab_box1'>
                        <div className='d-inline '>
                            <img className='Megaphone' src={Megaphone} />
                            <span className='rank wc'>Your daily tasks</span>
                        </div>

                        <div className='box invite invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 1</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>New Day</h4>
                                    <p className='icon_box_text mb-0'>50 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 2</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Tap x20</h4>
                                    <p className='icon_box_text mb-0'>50 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 3</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Tap x50</h4>
                                    <p className='icon_box_text mb-0'>100 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 4</h6>
                                    <img className='icon_box_icon' src={User} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Tap x100</h4>
                                    <p className='icon_box_text mb-0'>200 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <div className='tab_box1'>
                        <div className='d-inline '>
                            <img className='Megaphone' src={Megaphone} />
                            <span className='rank wc'>Follow Twitter handle</span>
                        </div>

                        <div className='box invite invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 1</h6>
                                    <img className='icon_box_icon1' src={Twitter} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Follow Twitter handle</h4>
                                    <p className='icon_box_text mb-0'>50 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 2</h6>
                                    <img className='icon_box_icon1' src={Telegram} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Join the Telegram chat</h4>
                                    <p className='icon_box_text mb-0'>50 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        <div className='box invite_box'>
                            <div className='d-flex icon_box'>
                                <div className='box_icon align-c'>
                                    <h6 className='nubmer'> 3</h6>
                                    <img className='icon_box_icon1' src={Web} />
                                </div>
                                <div className='icon_box_con align-c'>
                                    <h4 className='icon_box_title wc'>Visit website</h4>
                                    <p className='icon_box_text mb-0'>50 Grey Night Marble</p>
                                </div>
                            </div>
                            <button className='btn_p'>Claim</button>
                        </div>

                        
                    </div>
                </div>
            </div>
    </section>
      
    </>
  )
}

export default Tasks
