import React from 'react'
import Edit from '../img/to-do-list.png'
import Star from '../img/star1.png'
import Megaphone from '../img/megaphone.png'
import Thumb from '../img/thumb-up.png'
import Friendship from '../img/friendship.png'

const Referrals = () => {
  return (
    <>
        <section className='sec01_t text-center'>
            <div className='box d-inline'>
              <img className='Edit' src={Star} />
              <span className='rank wc'>0 Referrals</span>
            </div>
            <div className='bt_box_1'>
                <button className='btn_p'>Invite friends</button>
            </div>

            <div className='bt_box_2'>
                <div className='icon_box_con align-c'>
                        <div className='d-inline '>
                            <img className='Megaphone' src={Star} />
                            <span className='rank wc'>Follow Twitter handle</span>
                        </div>
                    <p className='icon_box_text mb-0'>20% of your friends get when they Tap</p>
                </div>
            </div>

            <div className='bt_box_3'>
                <button className='btn_p'>Claim</button>
            </div>

            <div className='bt_box_4'>
                <div className='d-inline '>
                    <img className='Megaphone' src={Thumb} />
                    <span className='rank wc'>My invite link:</span>
                </div>

                <form className='rel_form'>
                    <input className='form_input' type="text" placeholder='https://t.me/gray_night_marble/app?' />

                    <button type='submit' className='btn_p'>Copy</button>
                </form>
            </div>

            <div className='bt_box_5'>
                <div className='mb-3'>
                    <div className='d-inline'>
                        <img className='Megaphone' src={Friendship} />
                        <span className='rank wc'>Got friends? Invite them!</span>
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='d-inline'>
                        <img className='Megaphone' src={Megaphone} />
                        <span className='rank wc'>Bring them all into the game.</span>
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='d-inline mb-3'>
                        <img className='Megaphone' src={Thumb} />
                        <span className='rank wc'>More buddies,more coins</span>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Referrals