import React from 'react'
import Bnb from '../img/bnb.png'
import Timer from '../img/timer.png'
import code from '../img/code.svg'

const Payment = () => {
  return (
    <>
    <section className='sec01_t text-center'>
        <div className='box d-inline'>
            <img className='Edit' src={Timer} />
            <span className='rank wc'>Pending Payment</span>
        </div>

        <div className='box1_p pt-3'>
            <p className='rank wc mt-4'><i class="fa-solid fa-arrow-left me-2"></i>  Send 0.1 BNB to below address to proceed with your payment</p>
            <img className='bnb' src={Bnb} />
            <p className='rank wc mt-4'>Network - Binance Smart Chain ( BEP20 )</p>
            <img className='code' src={code} />
        </div>

        <form className='rel_form'>
                    <input className='form_input' type="text" placeholder='34HuwzDnSwxVRNCoyFCpQnRBXV2sVVmGUY' />

                    <button type='submit' className='btn_p'>Copy Address</button>
                </form>
    </section>
    </>
  )
}

export default Payment