import React from 'react'
import { useState } from 'react'
import User from '../img/user.jpg'
import Settings from '../img/settings.png'
import Menu from '../img/menu.png'
import Sec1 from '../img/sec1.png'
import Star from '../img/star.png'
import Earth from '../img/earth-globe.png'
import Rank from '../img/insignia.png'
import Add from '../img/add-group.png'
import Join from '../img/join.png'
import NFT from '../img/nft.png'
import Presale from '../img/profit.png'
import Ethereum from '../img/ethereum.png'
import Like from '../img/like.png'
import Referral from '../img/referral.png'
import Task from '../img/to-do-list.png'
import Tap from '../img/tap-to-pay.png'
import Boost from '../img/shuttle.png'
import Farm from '../img/money-bag.png'
import Ethereumon from '../img/axe_on.gif'
import Main1 from '../img/mainbg1.png'
import Main2 from '../img/mainbg2.png'
import $ from 'jquery';
const Home = () => {
  const [isActive, setIsActive] = useState(false);

  // $( ".kiss_box" ).click(function() {
  //     $( ".kiss_box" ).addClass( "axe1" );

  //     setTimeout(function() {
  //         hideAxe()
  //     }, 8000); // Delay of 3 seconds
  // });
  //   function hideAxe(){
  //     $(".kiss_box").removeClass("axe1");
  //   }  

  const handleClick = () => {
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 8000); // 8 seconds delay
  };
  return (
    <>
        <section className='sec1_h'>
          <h4 className='gnm wc text-left'>Grey Night Marble</h4>
          <div className='text-center'>
            <img className='sec1_img' src={Sec1} />
            <h4 className='gnm wc mt-4'>00000</h4>
          </div>
        </section>
        <section className='sec2_h'>
          <div className='box'>
            <ul className='d-flex home_list'>
              <li className='home_list_item text-center'>
                <img className='home_list_img' src={Earth} />
                <span className='point d-block'>WORLD RANK</span>
                <span className='rank wc d-block'>#1,438</span>
              </li>
              <li className='home_list_item home_list_item2 text-center'>
                <img className='home_list_img' src={Star} />
                <span className='point d-block'>POINTS</span>
                <span className='rank wc d-block'>590</span>
              </li>

              <li className='home_list_item text-center'>
                <img className='home_list_img' src={Rank} />
                <span className='point d-block'>LOCAL RANK</span>
                <span className='rank wc d-block'>#56</span>
              </li>
            </ul>
          </div>
        </section>
        <section className='sec3_h'>
          <div className='row'>
            <div className='col-6 btn_col btn_col1'>
              <h4 className='btn_h btn_h1 text-center'> <img className='btn_h_img' src={Add} /> Invite Friends</h4>
            </div>
            <div className='col-6 btn_col btn_col2'>
              <h4 className='btn_h btn_h2 text-center'> <img className='btn_h_img' src={Join} /> join Community</h4>
            </div>
            <div className='col-6 btn_col btn_col3'>
              <h4 className='btn_h btn_h3 text-center'> <img className='btn_h_img' src={NFT} /> NFT Marketplace</h4>
            </div>
            <div className='col-6 btn_col btn_col4'>
              <h4 className='btn_h btn_h4 text-center'> <img className='btn_h_img' src={Presale} /> Join Presale</h4>
            </div>
          </div>
        </section>
        <section className='sec4_h text-center'>
          <h4 className='gnm wc mt-4 box d-inline'>Wood</h4>
          
          <div className={`kiss_box ${isActive ? 'axe1' : ''}`} onClick={handleClick}>
            
            <img className=' Ethereum kiss kiss_off' src={Ethereum} alt="" />
            <img className='Ethereum kiss kiss_on' src={Ethereumon} alt="" />
          </div>
          <div className='mt-3'>
            <div className='box d-inline'>
              <img className='like' src={Like} />
              <span className='rank wc'>100/100</span>
            </div>
          </div>
        </section>
        
    </>
  )
}

export default Home   
