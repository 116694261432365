import React from 'react'
import Farmer from '../img/farmer.png'
import Star from '../img/star1.png'
import Axeman from '../img/MANAXE.png'

const Startfarmingera = () => {
  return (
    <>
        <section className='sec01_t text-center'>
            <div className='box d-inline'>
              <img className='Edit' src={Farmer} />
              <span className='rank wc'>Farm Grey Night Marble</span>
            </div>

            <div className='mt-5 text-center'>
                <div className='d-flex star_box'>
                  <img className='Edit' src={Star} />
                  <img className='Edit' src={Star} />
                  <img className='Edit' src={Star} />
                </div>
              <h4 className='gnm wc'>Claim Grey Night Marble and keep</h4>
              <p className='icon_box_text mb-0'>Farming while tapping!</p>
            </div>

            <div className='farming_box'>
                 <img className='axe_man' src={Axeman} />
            </div>
            
            <button className='btn_p'>Start Farming Era</button>
         </section>
    </>
  )
}

export default Startfarmingera