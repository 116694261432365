import React from 'react'
import Referral from '../img/referral.png'
import Task from '../img/to-do-list.png'
import Tap from '../img/tap-to-pay.png'
import Boost from '../img/shuttle.png'
import Farm from '../img/money-bag.png'
import { NavLink } from 'react-router-dom'
const Footer = () => {
  return (
    <>
    <footer class="">
          <ul class="footer_list d-flex">
            <li class="footer_list_item text-center active">
              <NavLink to="/Referrals">
                <img className='footer_icon' src={Referral} />
                <span class="footer_text  d-block">Ref</span>
              </NavLink>
            </li>
            <li class="footer_list_item text-center">
              <NavLink to="/Tasks">
                <img className='footer_icon' src={Task} />
                <span class="footer_text d-block">Task</span>
              </NavLink>
            </li>
            <li class="footer_list_item text-center">
              <NavLink to="/">
                <img className='footer_icon' src={Tap} />
                <span class="footer_text  d-block gc">Tap</span>
              </NavLink>
            </li>
            <li class="footer_list_item text-center">
              <NavLink to="/NFTMarketplace">
                <img className='footer_icon' src={Boost} />
                <span class="footer_text d-block">Boost</span>
              </NavLink>
            </li>
            <li class="footer_list_item text-center">
              <NavLink to="/Farming">
                <img className='footer_icon' src={Farm} />
                <span class="footer_text d-block">Farm</span>
              </NavLink>
            </li>
          </ul>
        </footer>
    </>
  )
}

export default Footer