import React from 'react'
import Sec1 from '../img/sec1.png'
import Balan from '../img/blans.png'
import Slid1 from '../img/slid1.png'
import Sli1 from '../img/sli_1.png'
import Sli2 from '../img/sli_2.png'
import Sli3 from '../img/sli_3.png'
import Sli4 from '../img/sli_4.png'
import Sli5 from '../img/sli_5.png'
import Sli6 from '../img/sli_6.png'

const Slider = () => {
  return (
    <>
    <section className='sec01_t text-center'>
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Wood League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Balan} />
                        <h4 className='gnm rc mt-4'>198,574,246</h4>
                    </div>
                </div>
                <div class="carousel-item">
                <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Bronze League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Sli1} />
                        <h4 className='gnm rc mt-4'>Form 5,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Silver League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Sli2} />
                        <h4 className='gnm rc mt-4'>Form 20,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Gold League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Sli3} />
                        <h4 className='gnm rc mt-4'>Form 100,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Platinum League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Sli4} />
                        <h4 className='gnm rc mt-4'>Form 500,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Sapphire League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Sli5} />
                        <h4 className='gnm rc mt-4'>Form 1,000,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Ruby League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Sli6} />
                        <h4 className='gnm rc mt-4'>Form 5,000,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Diamond League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Balan} />
                        <h4 className='gnm rc mt-4'>Form 10,000,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Crystal League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Balan} />
                        <h4 className='gnm rc mt-4'>Form 20,000,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Pearl League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Balan} />
                        <h4 className='gnm rc mt-4'>Form 50,000,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Emerald League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Balan} />
                        <h4 className='gnm rc mt-4'>Form 80,000,000</h4>
                    </div>
                </div>
                <div class="carousel-item">
                    <div className='text-center'>
                        <img className='sec1_img' src={Sec1} />
                        <h4 className='gnm wc mt-4'>00000</h4>
                        <h4 className='gnm wc mt-4'>Champion League</h4>
                    </div>
                    <button className='btn_p'>Total Share 0</button>

                    <div>
                        <img className='balan' src={Balan} />
                        <h4 className='gnm rc mt-4'>Form 100,000,000</h4>
                    </div>
                </div>
                
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"><img className='slid_icon slid_icon1' src={Slid1} /></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"><img className='slid_icon' src={Slid1} /></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        </section>
    </>
  )
}

export default Slider