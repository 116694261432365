
import React, { useState } from 'react';
import User from '../img/user.jpg';
import Settings from '../img/settings.png';
import Menu from '../img/menu.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggle = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleLinkClick = () => {
    setSidebarVisible(false); // Close the sidebar
  };

  return (
    <>
      <header className='d-flex header'>
        <div className='d-flex'>
          <img className='user' src={User} alt="User" />
          <div className='align-c'>
            <span className='user_name wc'>Yogesh</span>
          </div>
        </div>

        <div className='align-c'>
          <img className='header_icon set' src={Settings} alt="Settings" />
          
          <button
            type="button"
            className={`toggle toggle_btn ${sidebarVisible ? 'active' : ''}`}
            onClick={handleToggle}
          >
           <img className='header_icon' src={Menu} alt="Menu" />
          </button>
        </div>
      </header>

      <div className={`sidebar ${sidebarVisible ? 'sidebarshow' : ''}`} id='sidebar'>
        <ul className='sidbar_list'>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/" 
              onClick={handleLinkClick}
            >
              Home
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Tasks" 
              onClick={handleLinkClick}
            >
              Tasks
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Referrals" 
              onClick={handleLinkClick}
            >
              Referrals
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/NFTMarketplace" 
              onClick={handleLinkClick}
            >
              NFT
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Payment" 
              onClick={handleLinkClick}
            >
              Payment
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Farming" 
              onClick={handleLinkClick}
            >
              Farming
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Balance" 
              onClick={handleLinkClick}
            >
              Balance
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Slider" 
              onClick={handleLinkClick}
            >
              Slider
            </NavLink>
          </li>
          <li className='sidbar_list_item'>
            <NavLink 
              className='sidbar_list_link Poppins' 
              to="/Startfarmingera" 
              onClick={handleLinkClick}
            >
              Startfarmingera
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
