import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Tasks from './Pages/Tasks';
import Referrals from './Pages/Referrals'
import Header from './Pages/Header';
import Footer from './Pages/Footer';
import Main1 from './img/mainbg1.png'
import Main2 from './img/mainbg2.png'
import NFTMarketplace from './Pages/NFTMarketplace'
import Payment from './Pages/Payment'
import Farming from './Pages/Farming'
import Balance from './Pages/Balance'
import Slider from './Pages/Slider'
import Startfarmingera from './Pages/Startfarmingera'
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
function App() {
  const Public =()=>{
    return(
      <>
        <Header/>
          <Outlet/>
        <Footer/>
      </>
    )
  }
  return (
<>
    <div className='main_div'>
        <img className='Main1' src={Main1} />
        <img className='Main2' src={Main2} />
    <Router>
        <Routes>
          <Route element={<Public/>}>
            <Route path="/" element={<Home />} />
            <Route path="/Tasks" element={<Tasks />} />
            <Route path="/Referrals" element={<Referrals/>}/>
            <Route path="/NFTMarketplace" element={<NFTMarketplace/>}/>
            <Route path="/Payment" element={<Payment/>}/>
            <Route path="/Farming" element={<Farming/>}/>
            <Route path="/Balance" element={<Balance/>}/>
            <Route path="/Slider" element={<Slider/>}/>
            <Route path="/Startfarmingera" element={<Startfarmingera/>}/>
          </Route>
            
            </Routes>
            
            </Router>
    </div>
    </>
  );
}

export default App;
