import React from 'react'
import Sec1 from '../img/sec1.png'
import Balan from '../img/blans.png'

const Balance = () => {
  return (
    <>
        <section className='sec01_t text-center'>
          <div className='text-center'>
            <img className='sec1_img' src={Sec1} />
            <h4 className='gnm wc mt-4'>00000</h4>
          </div>
          <button className='btn_p'>Total Share Balance</button>

          <div>
              <img className='balan' src={Balan} />
              <h4 className='gnm rc mt-4'>198,574,246</h4>

              <h4 className='gnm wc mt-4'>Total Touches: 16,758,647</h4>
              <h4 className='gnm wc mt-4'>Total Players: 40,220</h4>
          </div>
        </section>
    </>
  )
}

export default Balance